<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap v-if="appType == 'viceprincipal'">
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <router-view> </router-view>
          </v-flex>
        </v-layout>
        <v-snackbar
          :timeout="3000"
          :value="true"
          right
          color="red"
          v-model="showsnackbar"
        >
          <span style="font-size: 20px"> {{ msg }}</span>
        </v-snackbar>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import store from "./../../store";
export default {
  data() {
    return {
        showsnackbar: false,
      msg: null,
      appLoading: false,
      ServerError: false,
          };
  },
  computed: {
    appType() {
      return store.state.userType;
    },
  },
  };
</script>